import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import DepartmentSettingsService from "./DepartmentSettingsService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const departmentSettingsService = DepartmentSettingsService();
let showAddIcon = false;

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return departmentSettingsService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return departmentSettingsService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return departmentSettingsService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return departmentSettingsService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return departmentSettingsService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function DepartmentSettingsPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("departmentSettings") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox textAlign="left" mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          textAlign="left"
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("departmentSettingsUpdate")}
            allowAdding={authProvider.isAuth("departmentSettingsSave")}
            allowDeleting={authProvider.isAuth("departmentSettingsDelete")}
          >
            <Popup title="Firma Ayarları" showTitle={true} />
            <Form textAlign="left">
              <Item textAlign="left" dataField="supplierId" dataType="number" />
              <Item textAlign="right" dataField="email" dataType="string" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            alignment="left"
            align="left"
            textAlign="left"
            caption={t("{{key, capitalize}}", { key: "supplierid" })}
            dataField="supplierId"
            dataType="number"
          >
            <RequiredRule />
          </Column>

          <Column
            alignment="left"
            align="left"
            textAlign="left"
            caption={t("{{key, capitalize}}", { key: "email" })}
            dataField="email"
            dataType="string"
          >
            <RequiredRule />
          </Column>

          <Column
            alignment="left"
            align="left"
            textAlign="left"
            caption={t("{{key, capitalize}}", { key: "department" })}
            dataField="department.name"
            dataType="string"
          >
            <RequiredRule />
          </Column>
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DepartmentSettingsPage;

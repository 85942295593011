import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Item,
  LoadPanel,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScrollView from "devextreme-react/scroll-view";
import GlobalInspectionRecordsService from "./GlobalInspectionRecordsService";
import GlobalInspectionService from "../GlobalInspectionService";

const authProvider = AuthProvider();
const inspectionService = GlobalInspectionService();
const inspectionRecordsService = GlobalInspectionRecordsService();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const dataSource = new DataSource({
  store: new CustomStore({
    key: "po",
    load(loadOptions: any) {
      return inspectionService.findAllPo(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return inspectionService.deletePo(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function GlobalInspectionRecordsPage() {
  const [dataGrid, setDataGrid] = useState();
  const [poDataGrid, setPoDataGrid] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedBoxRow, setSelectedBoxRow] = useState();
  const [openPoRecord, setOpenPoRecord] = useState();
  const [openBoxPopup, setOpenBoxPopup] = useState();
  const { t } = useTranslation();

  const epcTags = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      loadOptions.filter = ["globalInspection.id", "=", selectedBoxRow.id];
      console.log(selectedBoxRow);
      return inspectionRecordsService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  });

  const inspectionDataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load(loadOptions: any) {
        loadOptions.filter = [
          ["po", "=", selectedRow.po],
          "and",
          ["department.id", "=", selectedRow.department.id],
        ];
        return inspectionService.findAllPoText(loadOptions).then(
          (response) => ({
            data: response.items,
            totalCount: response.totalCount,
          }),
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      remove: (key: any) => {
        return inspectionService.deleteOne(key).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
    }),
  });

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={false}
            allowAdding={false}
            allowDeleting={authProvider.isAuth("inspecitonDelete")}
          ></Editing>
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
            showInfo={true}
          />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={() => {
                  setSelectedRow(item.data);
                  setOpenPoRecord(true);
                }}
              />
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "po" })}
            dataField="po"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="department.name"
            allowEditing={false}
          ></Column>
        </DataGrid>
      </SuiBox>
      <Footer />

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openPoRecord}
        key={11}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenPoRecord(false);
            dataGrid.instance.refresh();
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? "'" + selectedRow.po + "' " + t("{{key, capitalize}}", { key: "box.content" })
            : t("{{key, capitalize}}", { key: "box.content" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <ScrollView width="100%" height="100%">
            <DataGrid
              dataSource={inspectionDataSource}
              showBorders={false}
              remoteOperations={false}
              rowAlternationEnabled={true}
              ref={(ref) => {
                setPoDataGrid(ref);
              }}
            >
              <FilterRow visible={false} />
              <ColumnChooser enabled={false} />
              <LoadPanel enabled={true} />
              <Toolbar>
                <Item department="after">
                  <Button
                    icon="refresh"
                    onClick={() => {
                      poDataGrid.instance.refresh();
                    }}
                  />
                </Item>
                <Item name="searchPanel" />
                <Item name="columnChooserButton" />
              </Toolbar>
              <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
              <Editing
                mode="row"
                allowUpdating={false}
                allowAdding={false}
                allowDeleting={authProvider.isAuth("inspecitonDelete")}
              ></Editing>
              <Paging defaultPageSize={5} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                showInfo={true}
              />

              <Column
                caption=""
                dataField="id"
                dataType="number"
                sortOrder="desc"
                width={70}
                allowFiltering={false}
                allowSorting={false}
                allowEditing={false}
                cellRender={(item) => (
                  <Button
                    type="default"
                    icon="bulletlist"
                    onClick={() => {
                      setSelectedBoxRow(item.data);
                      setOpenBoxPopup(true);
                    }}
                  />
                )}
              />

              <Column
                caption={t("{{key, capitalize}}", { key: "boxNumber" })}
                dataField="boxNumber"
                sortOrder={"asc"}
                allowEditing={false}
              ></Column>
            </DataGrid>
          </ScrollView>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setOpenEPCRecord(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openBoxPopup}
        key={12}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenBoxPopup(false);
          }}
        >
          {selectedBoxRow !== undefined && selectedBoxRow !== null
            ? selectedBoxRow.po +
              " - " +
              selectedBoxRow.boxNumber +
              " " +
              t("{{key, capitalize}}", { key: "box.epc.detail" })
            : t("{{key, capitalize}}", { key: "box.epc.detail" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <DataGrid
            dataSource={epcTags}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              <Item name="searchPanel" />
              <Item name="columnChooserButton" />
            </Toolbar>
            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Column
              caption={t("{{key, capitalize}}", { key: "epc" })}
              width={400}
              dataField="epc"
            ></Column>
            <Column caption={t("{{key, capitalize}}", { key: "gtin" })} dataField="gtin"></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "serial" })}
              dataField="serial"
            ></Column>
          </DataGrid>
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default GlobalInspectionRecordsPage;

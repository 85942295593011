import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Item,
  LoadPanel,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle, Icon, ListItemIcon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScrollView from "devextreme-react/scroll-view";
import InspectionService from "../InspectionService";
import InspectionRecordsService from "./InspectionRecordsService";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const authProvider = AuthProvider();
const inspectionService = InspectionService();
const inspectionRecordsService = InspectionRecordsService();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const dataSource = new DataSource({
  store: new CustomStore({
    key: "po",
    load(loadOptions: any) {
      return inspectionService.findAllPo(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return inspectionService.deletePo(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function InspectionRecordsPage() {
  const [dataGrid, setDataGrid] = useState();
  const [poDataGrid, setPoDataGrid] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedBoxRow, setSelectedBoxRow] = useState();
  const [openPoRecord, setOpenPoRecord] = useState();
  const [openBoxPopup, setOpenBoxPopup] = useState();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const epcTags = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      loadOptions.filter = ["inspection.id", "=", selectedBoxRow.id];
      console.log(selectedBoxRow);
      return inspectionRecordsService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  });

  const inspectionDataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load(loadOptions: any) {
        loadOptions.filter = [
          ["po", "=", selectedRow.po],
          "and",
          ["department.id", "=", selectedRow.department.id],
        ];
        return inspectionService.findAllPoText(loadOptions).then(
          (response) => ({
            data: response.items,
            totalCount: response.totalCount,
          }),
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      remove: (key: any) => {
        return inspectionService.deleteOne(key).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
    }),
  });

  function onCellPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.sendedToInditex) {
        if (e.cellElement.getElementsByClassName("dx-link-delete").length !== 0) {
          e.cellElement.getElementsByClassName("dx-link-delete")[0].style.display = "none";
        }
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={false}
            allowAdding={false}
            allowDeleting={authProvider.isAuth("inspecitonDelete")}
          ></Editing>
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
            showInfo={true}
          />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={() => {
                  setSelectedRow(item.data);
                  setOpenPoRecord(true);
                }}
              />
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "po" })}
            dataField="po"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "department" })}
            dataField="department.name"
            allowEditing={false}
          ></Column>
        </DataGrid>
      </SuiBox>
      <Footer />

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openPoRecord}
        key={11}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenPoRecord(false);
            dataGrid.instance.refresh();
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? "'" + selectedRow.po + "' " + t("{{key, capitalize}}", { key: "box.content" })
            : t("{{key, capitalize}}", { key: "box.content" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <ScrollView width="100%" height="100%">
            <DataGrid
              dataSource={inspectionDataSource}
              showBorders={false}
              remoteOperations={false}
              rowAlternationEnabled={true}
              ref={(ref) => {
                setPoDataGrid(ref);
              }}
              onCellPrepared={onCellPrepared}
            >
              <FilterRow visible={false} />
              <ColumnChooser enabled={false} />
              <LoadPanel enabled={true} />
              <Toolbar>
                <Item location="before">
                  <Button
                    width={200}
                    style={{ marginLeft: "15px", float: "left" }}
                    text={t("{{key, capitalize}}", { key: "sendAllToInditex" })}
                    type="success"
                    align="left"
                    stylingMode="contained"
                    onClick={() => {
                      setLoadPanelVisible(true);
                      return inspectionRecordsService.saveApiSchedule(selectedRow).then(
                        (res) => {
                          poDataGrid.instance.refresh();
                          setLoadPanelVisible(false);
                        },
                        (err) => {
                          return err.json().then((r) => {
                            setLoadPanelVisible(false);
                            // throw r.errorMessage ? r.errorMessage : r.warningMessage;
                          });
                        }
                      );
                    }}
                  />
                </Item>
                <Item department="after">
                  <Button
                    icon="refresh"
                    onClick={() => {
                      poDataGrid.instance.refresh();
                    }}
                  />
                </Item>
                <Item name="searchPanel" />
                <Item name="columnChooserButton" />
              </Toolbar>
              <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
              <Editing
                mode="row"
                allowUpdating={false}
                allowAdding={false}
                allowDeleting={authProvider.isAuth("inspecitonDelete")}
              ></Editing>
              <Paging defaultPageSize={5} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                showInfo={true}
              />

              <Column
                caption=""
                dataField="id"
                dataType="number"
                sortOrder="desc"
                style={{ align: "left" }}
                allowFiltering={false}
                allowSorting={false}
                allowEditing={false}
                width={350}
                cellRender={(item) => {
                  return (
                    <>
                      <Button
                        width={100}
                        text={t("{{key, capitalize}}", { key: "epc.list" })}
                        type="info"
                        stylingMode="contained"
                        onClick={() => {
                          setSelectedBoxRow(item.data);
                          setOpenBoxPopup(true);
                        }}
                      />

                      {item.data.sendedToInditex === false ? (
                        <Button
                          width={200}
                          style={{ marginLeft: "15px" }}
                          text={t("{{key, capitalize}}", { key: "sendToInditex" })}
                          type="success"
                          stylingMode="contained"
                          onClick={async () => {
                            try {
                              setLoadPanelVisible(true);
                              await inspectionRecordsService.sendToInditex(item.data);
                              await poDataGrid.instance.refresh();
                            } catch (err) {
                              if (err.json) {
                                const errorResponse = await err.json();
                                console.error(
                                  errorResponse.errorMessage || errorResponse.warningMessage
                                );
                              } else {
                                console.error(err);
                              }
                            } finally {
                              setLoadPanelVisible(false);
                            }
                          }}
                        />
                      ) : (
                        <Button
                          width={200}
                          style={{ marginLeft: "15px" }}
                          text={t("{{key, capitalize}}", { key: "removeFromInditex" })}
                          type="danger"
                          stylingMode="contained"
                          onClick={() => {
                            return inspectionRecordsService.removeInditexRecord(item.data).then(
                              (res) => {
                                poDataGrid.instance.refresh();
                              },
                              (err) => {
                                return err.json().then((r) => {
                                  // throw r.errorMessage ? r.errorMessage : r.warningMessage;
                                });
                              }
                            );
                          }}
                        />
                      )}
                    </>
                  );
                }}
              />

              <Column
                caption={t("{{key, capitalize}}", { key: "boxNumber" })}
                dataField="boxNumber"
                sortOrder={"asc"}
                allowEditing={false}
              ></Column>
            </DataGrid>
          </ScrollView>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setOpenEPCRecord(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openBoxPopup}
        key={12}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenBoxPopup(false);
          }}
        >
          {selectedBoxRow !== undefined && selectedBoxRow !== null
            ? selectedBoxRow.po +
              " - " +
              selectedBoxRow.boxNumber +
              " " +
              t("{{key, capitalize}}", { key: "box.epc.detail" })
            : t("{{key, capitalize}}", { key: "box.epc.detail" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <DataGrid
            dataSource={epcTags}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              <Item name="searchPanel" />
              <Item name="columnChooserButton" />
            </Toolbar>
            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Column
              caption={t("{{key, capitalize}}", { key: "epc" })}
              width={400}
              dataField="epc"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "serialNumber" })}
              dataField="tagSerial"
            ></Column>
            <Column caption={t("{{key, capitalize}}", { key: "tid" })} dataField="tid"></Column>
          </DataGrid>
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default InspectionRecordsPage;
